<template>
  <div class="container-top" style="background-color:#F5F5F5">
    <div class="mobile">
      <div class="container">
        <div class="main-section">
          <div v-for="(section, idx) in sections" :key="'section-'+idx"
               :id="`section-mobile-${section.id}`" style="padding: 60px 20px">

            <div v-if="(section.title && section.title !== '') || (section.subTitle && section.subTitle !== '')"
                 class="margin-bottom-60">
              <div v-if="section.title && section.title !== ''"
                   class="mo-section-title main">{{ section.title }}</div>
              <div v-if="section.subTitle && section.subTitle !== ''"
                   class="mo-section-title-bold main">{{ section.subTitle }}</div>
            </div>

            <div class="head">
              <div v-if="section.head.primary && section.head.primary !== ''"
                   class="body4-bold primary margin-bottom-4">{{ section.head.primary }}</div>
              <div class="mo-section-title main">{{ section.head.title }}</div>
              <div class="flex-align">
                <div class="mo-section-title-bold main">{{ section.head.title2 }}</div>
              </div>
              <div v-if="section.head.label && section.head.label !== ''"
                   class="flex-center margin-top-8 bg-primary body5-medium"
                   style="display: inline-block; color: #fff; padding: 2px 8px 4px; border-radius: 4px">{{ section.head.label }}</div>
              <div class="body4 sub2 margin-top-16">{{ section.head.desc }}</div>
              <div v-if="section.head.text && section.head.text !== ''"
                   class="margin-top-16 body4 sub2">{{ section.head.text }}</div>
              <img v-if="section.head.img_mobile && section.head.img_mobile !== ''"
                   :src="img(section.head.img_mobile)" class="margin-top-32">
              <div v-if="section.mainButton && section.mainButton.length > 0"
                   class="flex-align margin-top-16">
                <button v-for="(button, idx) in section.mainButton"
                        :key="'mainButton-'+idx"
                        class="button flex-center unselect body4-medium"
                        :class="{'is-dark': idx === 0, 'is-gray': idx === 1}"
                        style="width: 50%; height: 40px; border-radius: 8px"
                        :style="mainButtonStyle(section.mainButton, idx)"
                        @click="clickButton(button)">
                  {{ button.name }}
                </button>
              </div>
            </div>

            <div v-if="section.content" class="margin-top-16">
              <div v-if="section.content.img_mobile && section.content.img_mobile !== ''">
                <img :src="img(section.content.img_mobile)">
              </div>
              <div class="body4 sub2 margin-top-16">{{ section.content.text }}</div>
            </div>

            <div v-if="section.feature" class="margin-top-60">
              <div class="head">
                <div v-if="section.feature.primary && section.feature.primary !== ''"
                     class="body4-bold primary margin-bottom-4">{{ section.feature.primary }}</div>
                <div class="mo-section-title main">{{ section.feature.title }}</div>
                <div class="flex-align">
                  <div class="mo-section-title-bold main">{{ section.feature.title2 }}</div>
                  <div v-if="section.feature.label && section.feature.label !== ''"
                       class="flex-center margin-left-8 bg-primary body5-medium"
                       style="color: #fff; padding: 2px 8px; border-radius: 4px">{{ section.head.label }}</div>
                </div>
                <div v-if="section.feature.desc && section.feature.desc !== ''"
                     class="body2 main margin-top-16">{{ section.feature.desc }}</div>

                <div v-if="section.feature.text && section.feature.text !== ''"
                     class="margin-top-16 body4 sub2">{{ section.feature.text }}</div>
                <img v-if="section.feature.img_mobile && section.feature.img_mobile !== ''"
                     :src="img(section.feature.img_mobile)" class="margin-top-40">

              </div>
            </div>



            <div v-if="section.packs && section.packs.length > 0"
                 style="margin-top: 60px">
              <div class="mo-section-title main">다양한 주문방식을 제공하고 싶다면</div>
              <div class="mo-section-title-bold main">플랫폼 팩을 추가할 수 있습니다.</div>
              <div class="margin-top-20">
                <div v-for="(pack, idx) in section.packs" :key="'pack-'+idx"
                     class="bg-white margin-bottom-16" style="padding:10px;border-radius:8px">

                  <div class="flex-align pack-item">
                    <img :src="img(pack.img_mobile)" style="width:120px;height:120px">
                    <div style="margin-left:16px">
                      <div class="subtitle6 main">{{ pack.title }}</div>
                      <div class="body4 sub2">{{ pack.desc }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="section.plugins && section.plugins.length > 0"
                 style="margin-top: 60px">
              <div class="mo-section-title main">필요한 기능이 있다면</div>
              <div class="mo-section-title-bold main">플러그인을 추가할 수 있습니다.</div>
              <div class="margin-top-20 plugins-wrapper">
                <template v-for="(plugin, idx) in section.plugins">
                  <div v-if="idx !== section.plugins.length - 1" :key="'plugin-'+idx"
                       class="flex-center bg-white"
                       style="height: 100px; padding: 0px 4px; border-radius: 4px">
                    <div style="text-align: center">
                      <img :src="icon(plugin.icon)" style="width: 24px; height: 24px">
                      <div class="margin-top-6 body5 sub2" style="height:40px">{{ plugin.name }}</div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="margin-top-20 body6 sub3">
                *더 많은 플러그인이 준비되어있으며, 팩과 플러그인은 지속적으로 업데이트됩니다.</div>
            </div>

            <div v-if="section.serviceList && section.serviceList.length > 0"
                 class="margin-top-60">
              <div class="mo-section-title main">비지니스 운영에 필요한 다양한</div>
              <div class="mo-section-title-bold main margin-bottom-20">추가서비스를 신청하세요.</div>
              <div style="display: grid;grid-template-columns: repeat(3, 1fr);grid-column-gap:8px;grid-row-gap:24px">
                <div v-for="(item,idx) in section.serviceList" :key="'serv-'+idx">
                  <img :src="`/static/img/home/${item.img}.svg`">
                  <div style="margin-top:4px" class="body6 main">{{ item.title }}</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "AppmarketInfo",
    components: {
    },
    watch: {

    },
    data() {
      return {
        popupMarket: undefined,
        defaultPackId: 70,
        sections:[
          {
            id: 1,
            bgColor: 'primary-light2',
            title: '플랫폼파이는 누구나 합리적 가격으로 런칭 할 수 있도록',
            subTitle: '불필요한 기능을 빼고 부가 기능이 필요한 고객만 플러그인으로 추가해서 이용할 수 있게 제공합니다.',
            img: 'img_page_api',
            tablet_img: 'img_page_api_tablet',
            head: {
              primary: '',
              title: '',
              title2: '앱마켓',
              label: '무료체험 기간동안 앱마켓도 무료!',
              link: '/market/list',
              img: 'img_appmarket_head',
              img_mobile: 'img_appmarket_head_mobile',
              desc: '플랫폼파이에서는 기본 팩에 다양한 디자인, 기능 조합을 추가할 수 있는 앱마켓을 이용할 수 있어요.\n' +
                '지금 바로 14일 동안 앱마켓에서 모든 기능을  추가해보며 내 서비스를 구현해 보고 이용을 결정해보세요!'
            },
            serviceList: [
              {
                title: '콘텐츠 스토리지',
                img: 'content_storage'
              },
              {
                title: 'KG 이니시스 PG',
                img: 'kg_inisis'
              },
              {
                title: '카카오 페이 간편결제',
                img: 'kakao_pay'
              },
              {
                title: 'GA',
                img: 'img_GA'
              },
              {
                title: '도메인',
                img: 'img_domain'
              },
              {
                title: '앱포팅',
                img: 'img_porting'
              }
            ],
            mainButton: [],
            apps: [

            ],
            packs: [
              {
                title: '상품 택배 배송 팩',
                desc: '유저가 파트너들의 상품 구매 및 택배배송을 받을 수 있습니다.',
                img_mobile: 'pack_delivery_mobile'
              },
              {
                title: '서비스 예약 팩(기간단위)',
                desc: '기간(일) 단위로 유저가 파트너들의 서비스 상품을 예약할 수 있습니다.',
                img_mobile: 'pack_period_mobile'
              },
              {
                title: '서비스 예약 팩(시간단위)',
                desc: '시/분 단위로 유저가 파트너의 서비스 상품을 예약할 수 있습니다.',
                img_mobile: 'pack_time_mobile'
              },
              {
                title: '커뮤니티 팩',
                desc: '업로드 된 게시글을 통해 댓글/대댓글로 소통할 수 있습니다.',
                img_mobile: 'pack_community_mobile'
              },
            ],
            plugins: [
              {
                icon: 'u:file-alt-color',
                name: '추가 게시판'
              },
              {
                icon: 'u:clipboard-alt-color',
                name: '공지사항 팝업'
              },
              {
                icon: 'u:user-color',
                name: '본인인증'
              },
              {
                icon: 'u:comment-lines-color',
                name: '앱푸시 알림'
              },
              {
                icon: 'u:folder-upload-color',
                name: '상품엑셀파일업로드'
              },

              {
                icon: 'u:user-square-color',
                name: '파트너 기획전'
              },
              {
                icon: 'u:user-check-color',
                name: '성인인증'
              },
              {
                icon: 'u:brightness-plus-color',
                name: '적립금/쿠폰 관리'
              },
              {
                icon: 'u:database-alt-color',
                name: '상품맞춤DB관리'
              },
              {
                icon: 'u:database-alt-color',
                name: '파트너맞춤DB관리'
              },
              {
                icon: 'u:database-alt-color',
                name: '회원맞춤DB관리'
              },
              {
                icon: 'u:image-upload-color',
                name: '상품이미지 일괄등록'
              },
              {
                icon: 'u:product-recent-color',
                name: '최근 본 상품'
              },
              {
                icon: 'u:partner-product-color',
                name: '파트너별상품기획전'
              },
              {
                icon: 'u:print-color',
                name: '주문 상세페이지 인쇄'
              },
              {
                icon: 'plugin:naver-pay',
                name: '네이버페이'
              }
            ],
            list: [],
            links: {

            }
          }
        ]
      }
    },
    created() {
    },
    mounted() {
    },
    methods: {
      onMessageMarket(e) {
        if(e.data.close) {
          this.popupMarket.close();
          this.removeMessageEvent();
          if(e.data.redirectUrl) {
            setTimeout(()=>{
              window.open(e.data.redirectUrl);
            },300);
          }
        }
      },
      clickButton(item) {
        if(item.link.indexOf('/market/detail')>-1) {
          let width = 1231;
          let height = 760;
          this.popupMarket = window.open(item.link, 'appMarket', `width=${width}, height=${height}, top=0, left=0, menubar=no, toolbar=no, titlebar=yes`)
          this.addMessageEvent();
        } else if (item.linkType === 'outlink') {
          const a = document.createElement('a');
          a.href = item.link;
          a.target = '_blank';
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          a.parentNode.removeChild(a);
        } else {
          this.routerPush(`${item.link}`);
        }
      },
      icon(img) {
        return `/static/icon/${img.replace(':','_')}.svg`;
      },
      img(img) {
        return `/static/img/${img}.png`;
      },
      tagStyle(tags, idx) {
        let deco = {
          marginRight: '4px'
        }
        if (idx === tags.length - 1) {
          deco.marginRight = '0px';
        }
        return deco;
      },
      buttonMenuStyle(buttonMenu, idx) {
        let deco = {
          marginRight: '12px'
        }
        if (idx === buttonMenu.length - 1) {
          deco.marginRight = '0px';
        }
        return deco;
      },
      mainButtonStyle(mainButton, idx) {
        let deco = {
          marginRight: '16px'
        }
        if (idx === mainButton.length - 1) {
          deco.marginRight = '0px';
        }
        return deco;
      },
      mobilePackImgStyle(packs, idx) {
        let deco = {
          marginRight: '16px'
        }
        if (idx === packs.length - 1) {
          deco.marginRight = '0px'
        }
        return deco;
      },
      sectionContentPcStyle(content) {
        let deco = {
          display: 'grid',
          alignItems: 'center'
        }
        if (content.img !== '' && content.text !== '') {
          deco.gridTemplateColumns = 'repeat(2, 1fr)';
          deco.gridGap = '0px 98px';
        }
        return deco;
      },
    },
    computed: {
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .bg-white
    background-color white
  .bg-dark
    background-color $sub
    color white
  .bg-gray3
    background-color $gray3
  .bg-primary
    background-color $primary
  .bg-primary-light2
    background-color $primary-light2
  .border-gray
    border 1px solid $gray
  .border-top-gray
    border-top 1px solid $gray
  .border-primary-light1
    border 1px solid $primary-light1
  .button-main
    border 1px solid $main
    color $main
  .section-menu-container
    border-bottom 1px solid $gray
  .bottom-menu-btn-wrap
    border-top 1px solid $gray
  .selected
    color $primary
    border-bottom 3px solid $primary


  .container
    word-break keep-all
    white-space pre-line
  .pc
    .section-head-grid-container
      display grid
      grid-gap 0px 120px
      align-items center
    .section-grid-list
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 0px 120px
      align-items center
    .section-grid-info
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 0px 80px
    .section-grid-info-item
      display grid
      grid-template-columns repeat(3, 1fr)
      grid-gap 12px
    .section-grid-default-pack
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 0px 100px
      align-items center
    .pack-info-wrapper
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 0px 24px
      align-items center
    .menuitem-wrapper
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 12px 20px
    .menuitem-wrapper-2
      display grid
      grid-template-columns repeat(3, 1fr)
      grid-gap 12px 20px
    .apps-wrapper
      display grid
      grid-template-columns repeat(3, 1fr)
      grid-gap 0px 24px
    .packs-wrapper
      display grid
      grid-template-columns repeat(3, 1fr)
      grid-gap 0px 24px
    .plugins-wrapper
      display grid
      grid-template-columns repeat(4, 1fr)
      grid-gap 16px 16px
    .split-bar
      border-top 1px solid $gray1
      width 30px
      height 0px
      transform rotate(90deg)
    .card-wrapper
      display grid
      grid-gap 24px
      grid-template-columns repeat(2, 1fr)
    .card-info
      border-radius 12px
      padding 32px
      box-shadow 0px 10px 20px rgba(0, 0, 0, 0.04)
    .quick-builder-button
      background-color $primary
      color #fff
    .prime-button
      background-color $main
      color $primary
  .mobile
    .section-grid-info-item
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 8px 10px
    .section-defaultpack-menu
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 10px
    .menuitem-wrapper
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 4px 4px
    .card-wrapper
      display grid
      grid-template-columns repeat(1, 1fr)
      grid-gap 12px 0
    .card-info
      border-radius 8px
      padding 20px 20px 24px
      box-shadow 0px 10px 20px rgba(0, 0, 0, 0.04)
    .drag-scroll-mobile
      white-space nowrap
      overflow-x scroll
      scroll-snap-type x mandatory
      -webkit-overflow-scrolling touch
      overflow-y hidden

    .drag-scroll-mobile::-webkit-scrollbar
      display none

    .apps-wrapper
      display grid
      grid-template-columns repeat(1, 1fr)
      grid-gap 12px 0
    .packs-wrapper
      display grid
      grid-template-columns repeat(1, 1fr)
      grid-gap 24px 0
    .plugins-wrapper
      display grid
      grid-template-columns repeat(3, 1fr)
      grid-gap 8px 8px
      word-break normal
    .menuitem-wrapper-2
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 10px 10px
    .bottom-menu-btn-wrap
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 0px 12px
  @media (max-width 1024px)
    .container
      padding 0px
</style>
